#precios{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5em;
}
.card{
    max-height: 160em;
}
.card-title {
    text-align: center;
}
.card-text{
    padding: 0;
    font-size: 14px;
    text-align: center;
}
.card-text p {
    padding: 1em 1em;
}
.card-text li{
    width: 100%;
    text-align: left;
    letter-spacing: .2em;
    list-style: "➤ ";
}
button{
    width: 100%;
}
button a{
    color: white;
}
.card-footer{
    text-align: center;
}
@media screen and (max-width:1300px) {
    .card-text{
        font-size: 1rem
    }
}
@media (max-width: 800px) {
    .card-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
@media screen and (max-width:580px) {
    .card{
        margin: 0 auto;
    }
}

.proyectos{
    background: cadetblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5em;
}
#sobreMi{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5em;
}
#sobreMi .content{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
#sobreMi img {
    margin-left: 1em;
    height: 12em;
    border-radius: 15px;
    z-index: 1;
}
#misEstudios{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5em;
}
#colaboraciones{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#colaboraciones img{
    width: 8em;
    height: 8em;
    border-radius: 15px;
}
#colaboraciones .content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1em;
    width: 100%;
}
.colabs{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.proyectContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
iframe{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30em;
    scroll-behavior: smooth;
}
.card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    background-color: burlywood;
    box-shadow: 0 0 4px black;
    color: black;
    border: 1px solid burlywood;
    border-radius: 8px;
    padding: 2px;
    /*Para separar las capas*/
    width: 60%;
    min-height: 300px;
    /*Para las capas que estén casi vacias*/
    margin: 10px;
}

h2 {
    background-color: blanchedalmond;
    box-shadow: 0 0 4px black;
    text-align: center;
    color: black;
    padding: 12px;
    margin: 10px;
    text-shadow: 1px 1.5px rgb(133, 1, 133), 0 10px 4px purple;
    z-index: 1;
}
p{
    padding: 1em 3em;
    text-align: justify;
    font-size: 1.3em;
    z-index: 1;
    margin: 0;
}

@media screen and (max-width:800px) {
    #sobreMi .content{
        display: block;
    }
}
@media screen and (max-width:580px) {
    #colaboraciones .content{
        display: block;
    }
    .card{
        width: 90%;
    }
    p{
        padding: 1em 1em;
        font-size: 1em;
    }
}
/* Aquí se estiliza el componente Header. */
.header {
    overflow: hidden;
    height: 30em;
    position: relative;
    z-index: 2;
}

.header:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.6;
    background: url("./assets/header2.jpg") no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}

#logo {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: flex-start;
    position: absolute;
    left: 1%;
    z-index: 3;
}

h1 {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50% -50%);
    width: 100%;
    text-transform: uppercase;
    background-color: rgb(255, 235, 205);
    border: 1px solid black;
    box-shadow: 0 0 4px black;
    text-align: center;
    color: black;
    padding: 12px;
    z-index: 2;
    text-shadow: 0 0 10px purple;
}
#content-header{
    position: absolute;
    top: 12em;
    right: 0;
    display: flex;
    flex-direction: column;
    z-index: 2;
    width: 50%;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}
#parrafo-header {
    text-align: justify;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 3px black;
    font-size: 15px;
}
#contacto-header{
    font-size: 1.5em;
    text-align: center;
} 
.nav-superior {
    position: absolute;
    width: 97%;
    top: 20%;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    background: rgb(255, 235, 205);
    box-shadow: 0 0 10px black;
    border: 1px solid black;
    padding: .5em 0;
    right: 0;
    z-index: 2;
    gap: 2em;
}

li {
    list-style: none;
    margin-right: 1em;
}

a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 22px;
    transition: all .5s;
    text-shadow: 2px 2px 2px rgb(104, 104, 104);
}

a:hover {
    color: blueviolet;
    padding-right: 1em;
}
@media screen and (max-width:1000px) {
    #content-header{
        width: 80%;
    }
    .nav-superior li a{
        font-size: 20px
    }
}
@media screen and (max-width:840px) {
    .nav-superior li a{
        font-size: 15px
    }
}
@media screen and (max-width:600px) {
    #content-header{
        width: 100%;
        top: 50%;
        font-size: 10px;
    }
    .nav-superior {
        gap: 0;
    }
}
@media screen and (max-width:500px) {
    .nav-superior{
        display: block;
        text-align: center;
    }
    #content-header{
        width: 100%;
        top: 50%;
    }
    .nav-superior li a{
        font-size: 15px
    }
    
}
@media screen and (max-width:400px) {
    #content-header{
        width: 100%;
        top: 50%;
        font-size: 10px;
    }
    .nav-superior li a{
        font-size: 15px
    }
}
@media screen and (max-width:350px) {
    .nav-superior li a{
        font-size: 15px
    }
}
@media screen and (max-width:300px) {
    .nav-superior li a{
        font-size: 15px
    }
    #parrafo-header{
        font-size: 12px;
    }
}
footer{
    width: 97%;
    height: 50px;
    margin: 50px 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: rgb(255, 235, 205);
    box-shadow: 0 0 10px black;
    border: 1px solid black;
    position: absolute;
}
footer div p{
    display: flex;
    align-items: center;
    font-weight: bold;
    margin: 0;
} 
nav{
    display: flex;
    align-items: center;
    font-weight: bold;
} 

.nav-inferior{
    font-size: 1px;
    display: flex;
    z-index: 2;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 40em;
}


.nav-inferior li a{
    font-size: 22px;
}


@media screen and (max-width:1250px) {
    footer{
        height: 200px;
    }
    .nav-inferior{
        display: block;
    }
    
}
@media screen and (max-width:1150px) {
    .nav-inferior li a{
        font-size: 18px
    }
    footer div p{
        font-size: 18px
    }
}
@media screen and (max-width:840px) {
    
    .nav-inferior li a{
        font-size: 18px
    }
    footer div p{
        font-size: 18px
    }
}
@media screen and (max-width:500px) {
    .nav-inferior li a{
        font-size: 18px
    }
    footer div p{
        font-size: 18px
    }
    
}
@media screen and (max-width:400px) {
    .nav-inferior li a{
        font-size: 15px
    }
    footer div p{
        font-size: 15px
    }
    
}